<template>
	<CCard class="p-4 mt-2">
		<div>
			<div class="pl-3 pr-3 pb-3"> 
	    		<CRow> 
			     	<CCol lg="12">
			      		<div style="text-align: right"> 
							<CButton  v-if="has_role(['pahrsek admin'])"  class="ml-1" size="sm" color="warning" @click="reset" ><i class="fa fa-undo"></i> Reset Odometer</CButton>
				      		<CButton class="ml-1" size="sm" color="info" @click="showUpdateModal"><i class="fa fa-plus"></i> Add Odometer Entry</CButton>
			      		</div>
					</CCol>
				</CRow>
				<br/>
	        	
	        	<CRow class="mb-2">
	        		<CCol lg="12">
						<div class="table-responsive-sm table-responsive-md">
	        			<CDataTable
							:tableFilter="{ placeholder: 'Date,Reading,Source' }"
							pagination
							:itemsPerPage="10"
							itemsPerPageSelect
							:loading="isLoading"
              
					      	:items="odoList.data"
					      	:fields="[
								{
									key: 'date_label', 
									label: 'Date'
								},
								{
									key: 'reading_label', 
									label: 'Reading'
								}, 
								{
									key: 'type_label', 
									label: 'Source'
								}, 
								{
								key: 'action',
								label: 'Action',
								_style: { width: '20px'}
							}
							]"
							hover
							striped
							small
							fixed
							custom
					    >   
						<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	
						                <CDropdownItem v-if="config.getPermission('vehicle').update" @click="updateOdo(item)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vehicle').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					</div>
	        		</CCol> 
	        	</CRow> 
	    	</div>
		</div>
		<CModal  title="Odometer Entry" :show.sync="showModal" color="info">  
			<form @submit.prevent="submit">
				<div class="pl-3 pr-3 pb-3"> 
					<CRow class="mb-2">
						<CCol lg="12">
							<label>Odometer (Reading) <span class="text-danger">*</span></label>
							<i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
							<CInput
							   v-mask="'######'"
								type="number"
								required
								v-model="odoParams.reading" 
								/>
								<div  class="mb-2" style="margin-top: -10px; font-size:11px " >
										<span>Current Odometer: {{ odometer_value }}
										</span>
							</div>
						</CCol> 
					</CRow>
					<CRow class="mb-2">
						<CCol lg="12">
							<label>Record Date <span class="text-danger">*</span></label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="odoParams.date"
								placeholder="Enter date" 
								:typeable="true" 
								required 
							>
							</Datepicker>  
						</CCol>
					</CRow>
					<span class="text-danger">{{message}}</span>
				</div>
				<div slot="footer" class="w-100">
					
					<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" type="submit">
						Save
					</CButton>  
					
				</div> 
			</form>
			<div slot="footer" class="w-100"></div>
		</CModal>
		<!-- <OdometerModal :details="details" @showModal="updateModal = $event; getVehicle();" :odometer="odometer_value" :showModal="updateModal" /> -->
	</CCard>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2'; 
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment';  
// import OdometerModal from './odometer_modal.vue';

export default {
	mounted(){  

		this.getVehicle();  
		this.getOdometerData(this.$route.params.id)

	},
	props: ['reload'],
	watch: {
		reload(val) {
			if(val)
			this.getVehicle(); 
		}
	},
	data(){
		return{    
			showModal: false,
			dataList: [], 
			odometer_value:0, 
			bookingParams: {
				booking_no: "", 
				plate_no:"",
				driver_id:"",
				driver: {
					name: ""
				},
				vehicle: {
					plate_no: ""
				}  
			},
			dataParams: {
				booking: {
					booking_no: ""
				}
			},
			editMode: false, 
			odoList: {
				data: []
			}, 
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment,
			reading: 0,
			details: {
				
				plate_no: "",
				maker_model: null
			},
			updateModal: false,
			odoParams: {},
			message: ''
		}
	},
	name: 'OdometerData',
	
	components: { Datepicker, vSelect},
	methods: { 
		backToVehicleDetails(){
	    	this.$router.push('/data/view_vehicle/'+this.$route.params.id)
	    },

		getVehicle(){
			axios.get(config.api_path+'/vehicle/'+this.$route.params.id)
			.then(response=>{
				this.details = response.data.data
				this.getData();
			})
		},

		getOdometerData(i){
		// this.data_loading.odometer = true;

		
			axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
				const {data, status} = res.data;
				const odo = data?.odo_reading ?? "0";

				this.$set(this.dataParams,'current_odometer', odo);
				this.odometer_value=odo;
				// this.data_loading.odometer = false;
			})
			.finally(fin => {
				// this.data_loading.odometer = false;
			})
			
	    },

		getData(){
			this.$showLoading(true)
			axios.get(config.api_path+'/odometer?vehicle_id='+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				response.data.data = response.data.data.map((value, index)=>{

					value.date_label = moment.unix(value.date).format('MMMM DD, YYYY')
					if(value.remarks == "FuelRecords"){
						value.type_label = "Fuel Record";
						value.reading_label = value.reading +"km"
					}
					else if(value.remarks == "WorkOrder"){
						value.type_label = "Work Order";
						value.reading_label = value.reading +"km"
					}
					else if(value.remarks == "Vehicle"){
						value.type_label = "Vehicle";
						value.reading_label = value.reading +"km"
					}
					else{
						value.type_label = 'Bookings -' + value.remarks
						value.reading_label = value.reading+" km"
					}
					
					return value;
				});
				
				this.odoList = response.data;
				if(this.odoList.total > 0){ 
					this.reading = this.odoList.data[0].reading_label
				}
			})
			.catch(err => {
			this.$showLoading(false)
		}) 

		}, 

	    backToTable(){
	    	this.$router.push('/booking/'+this.$route.params.id+'/mts')
	    }, 

	  
		showUpdateModal(){
			this.odoParams = {
				reading: 0,
				date: null
			};
			this.showModal = !this.showModal;
			this.editMode = false;
		},

		updateOdo(item){
			this.title = "Update Odometer";
	    	this.odoParams = {...item};
			this.odoParams.date = item.date ? moment.unix(item.date).format('MM/DD/YYYY') : null;
	    	this.showModal = !this.showModal;
			this.editMode = true;
	    },

		deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				text: 'Are you sure you want to delete this entry?',
				reverseButtons:true, 
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/odometer/'+item.id)
			    	.then(response => {
			    		this.getData();
			    		Swal.fire({
							title: 'Success!',
							text: "Odometer enry successfully deleted",
							icon: 'success', 
						})
						
			    	}) 
				}
			})  
	    },

		submit(){
			if(this.editMode) {
				this.message = "";
				let param = {
					date: this.odoParams.date ? moment(this.odoParams.date).unix() : null,
					reading : this.odoParams.reading,
					temp_date : this.odoParams.date
				}
				axios.put(`${config.api_path}/odometer/${this.odoParams.id}`, param).then(res => {
					if(res.data.status == "error"){
						this.message = res.data.message;
						console.log(this.odoParams.date)
						return;
					}
					this.odoParams = {
							reading: 0,
							date: null,
						}
					this.showModal = false;
					this.getData()
				})
			}
			else {
				this.message = "";
				this.odoParams.vehicle_id = this.details.id;
				var temp_date = this.odoParams.date;
				this.odoParams.temp_date = temp_date;
				this.odoParams.date = moment(this.odoParams.date).startOf('day').unix();
				this.odoParams.remarks='Vehicle';

				
				axios.post(config.api_path+'/odometer', this.odoParams)
				.then(response=>{  
					if(response.data.status == "error"){
						this.message = response.data.message;
						
					}
					else{
						this.odoParams = {
							reading: 0,
							date: null,
						}
						this.message = ""
						this.showModal = false;
						this.getData()
					}
					// this.odoParams = {
					// 		remarks:'Vehicle'
					// 	}
					
					});
			}
				
		},
		reset(){
			Swal.fire({
				icon:'warning',
				text: 'Are you sure you want to reset the odometer?',
				reverseButtons:true, 
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, reset it!'
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.get(config.api_path+'/vehicle/odometer-reset/'+this.details.id)
					.then(response => {
						this.getData();
						Swal.fire({
							title: 'Success!',
							text: "Odometer was successfully reset",
							icon: 'success', 
						})
						
					}) 
				}
			})  
		}

 	}
}
</script>
